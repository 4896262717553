import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import tr from '../MultLanguage/tr.json';
import en from '../MultLanguage/en.json';
import de from '../MultLanguage/de.json';
import es from '../MultLanguage/es.json';
import fr from '../MultLanguage/fr.json';
import lt from '../MultLanguage/lt.json';
import ru from '../MultLanguage/ru.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'
switch (localStorage.getItem('Language') || 'en') {
	case "tr":
		setTranslations({ tr })
		setDefaultLanguage(language.toString())
		break;
	case "fr":
		setTranslations({ fr })
		setDefaultLanguage(language.toString())
		break;
	case "es":
		setTranslations({ es })
		setDefaultLanguage(language.toString())
		break;
	case "ru":
		setTranslations({ ru })
		setDefaultLanguage(language.toString())
		break;
	case "lt":
		setTranslations({ lt })
		setDefaultLanguage(language.toString())
		break;
	case "de":
		setTranslations({ de })
		setDefaultLanguage(language.toString())
		break;
	default:
		setTranslations({ en })
		setDefaultLanguage('en')

}

class DeliveryProcess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			foodmanimg: require("../../static/img/food-man.png"),
			responsive: {
				0: {
					items: 1,
				},
				767: {
					items: 2,
				},
				991: {
					items: 3,
				},
			},
		}
	}

	render() {
		const { t } = this.props
		return (
			<div>
				<section className="Works-food-delivery">
					<div className="container">
						<div className="row">
							{/* <div className="col-md-12">
								<div className="food-add-slider ">
									<OwlCarousel className="owl-theme" loop margin={0} nav items={3} dots={false} id="add-slider" responsive={this.state.responsive}>
										<div className="item">
											<div className="add_img">
												<a href="javascript:Void(0);">
													<img src={require("../../static/img/add-product-1.png")} alt="" />
												</a>
											</div>
										</div>
										<div className="item">
											<div className="add_img">
												<a href="javascript:Void(0);">
													<img src={require("../../static/img/add-product-2.png")} alt="" />
												</a>
											</div>
										</div>
										<div className="item">
											<div className="add_img">
												<a href="javascript:Void(0);">
													<img src={require("../../static/img/add-product-1.png")} alt="" />
												</a>
											</div>
										</div>
										<div className="item">
											<div className="add_img">
												<a href="javascript:Void(0);">
													<img src={require("../../static/img/add-product-2.png")} alt="" />
												</a>
											</div>
										</div>
									</OwlCarousel>
								</div>
							</div> */}
							<div className="col-md-12">
								<div className="work-heading">
									<h2>{t("dely.How it Works")} - <span>{t("home.Food Delivery")}</span> </h2>
								</div>
							</div>
							<div className="col-md-12">
								<div className="food-delivery-outer">
									<div className="left-outer">
										<img src={require("../../static/img/food-delivery-idea.png")} alt="" className="idea-img" />
										<div className="repet-flip">
											<div className="product_img">
												<figure className="imghvr-flip-vert">
													<a href="javascript:Void(0);">
														<img src={require("../../static/img/delivery-user.png")} alt="" />
													</a>
													<figcaption>
														<a href="javascript:Void(0);">
															<img src={require("../../static/img/delivery-user-1.png")} alt="" />
														</a>
													</figcaption>
												</figure>
											</div>
											<h5>{t("dely.Customer")}</h5>
										</div>
									</div>
									<div className="left-one">
										<a href="javascript:Void(0);"> <img src={require("../../static/img/food-delivery.png")} alt=""									  
										/>
										</a>
									</div>
									<div className="left-three">
										<span className="contact-line">
											<img src={require("../../static/img/line.png")} alt="" />
										</span>
										<div className="repet-flip">
											<h5>{t("dely.Restaurant / Cafe")}</h5>
											<div className="product_img">
												<figure className="imghvr-flip-vert">
													<a href="javascript:Void(0);">
														<img src={require("../../static/img/Cafe-1.png")} alt="" />

													</a>
													<figcaption>
														<a href="javascript:Void(0);">
															<img src={require("../../static/img/Cafe-1-white.png")} alt="" />

														</a>
													</figcaption>
												</figure>
											</div>
										</div>
									</div>
									<div className="left-three">
										<span className="contact-line">
											<img src={require("../../static/img/line.png")} alt="" />
										</span>
										<div className="repet-flip">
											<h5>{t("dely.Delivery service")}</h5>
											<div className="product_img">
												<figure className="imghvr-flip-vert">
													<a href="javascript:Void(0);">
														<img src={require("../../static/img/delivery-boy.png")} alt="" />
													</a>
													<figcaption>
														<a href="javascript:Void(0);">
															<img src={require("../../static/img/delivery-boy-white.png")} alt="" />
														</a>
													</figcaption>
												</figure>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default withTranslation(DeliveryProcess);