const USER_LOGIN = "USER_LOGIN";
const USER_LOGOUT = "USER_LOGOUT";
const LSP_LIST = "LSP_LIST";
const CHAT_LIST = "CHAT_LIST";

export {
	USER_LOGIN, 
	USER_LOGOUT, 
	LSP_LIST,
	CHAT_LIST
};