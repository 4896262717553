import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { capitalize } from 'lodash';
import connect from "react-redux/es/connect/connect";
import user_nav from './user_nav';

class UserSidebar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			current_location: window.location && window.location.hash,
			slide: false,
			toggle: this.props.toggle,
		}

		this.onSlideBtnClick = this.onSlideBtnClick.bind(this);
		this.onSlideClose = this.onSlideClose.bind(this);
		this.removeSideBar = this.removeSideBar.bind(this);
	}

	onClickHandle() {
		const current_location = window.location.hash || '';
		this.setState({ current_location });
	}

	onSlideBtnClick() {

		this.setState({ slide: true });

	}

	onSlideClose() {
		this.setState({ slide: false });
	}

	removeSideBar() {
		this.setState({ slide: false });
	}

	render() {
		const user = this.props.state && this.props.state.user;
		return (
			<div className="sidebar_outer">
				<div className="siderbarmenu-button" style={{ "display": "none" }}><Button className="side-btn" onClick={this.onSlideBtnClick}>Sidebar Menu</Button></div>
				<span className="custon-sidebar">
					<nav className={`side_bar ${this.state.slide ? 'main' : ''}`} >
						<div className="profile_nav">
							{this.state.slide && <Button onClick={this.onSlideClose}><i className="fa fa-times" aria-hidden="true"></i></Button>}
							<Link to="/profile" className="profile_data"> <span className="img"><img src={user && user.user_image} /></span>
								<h2>{capitalize((user && user.first_name) || '') || user.mobile || ''}</h2>

							</Link>
						</div>
						<div className="menu_nav">
							<ul className="list_style">

								{user_nav.items.map((nav, index) =>
									<li json={JSON.stringify(nav)} key={index} className={`${nav.icon} ${(this.state.current_location === `#${nav.url}`) ? 'active' : ''}`} onClick={this.onClickHandle.bind(this)}>
										<Link className="name" to={nav.url}><span className="name"><i className={nav.icon}></i> {nav.name}</span></Link>
									</li>
								)}

							</ul>
						</div>
					</nav>
					<div className="overlay" onClick={this.removeSideBar}></div>
				</span>
			</div>

		);
	}
}

const mapStateToProps = state => {
	//return { user: state.user };
	return { state };
};

export default connect(mapStateToProps)(UserSidebar);