import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, capitalize } from "lodash";
import PubNubReact from 'pubnub-react';
import connect from "react-redux/es/connect/connect";
import { userLogin } from "../../actions/index";
import config from "../../constants/config";
import Geocode from "react-geocode";
import Swal from 'sweetalert2';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import apiUrl from '../../constants/apiPath';
import Helper from '../../constants/helper';
import {Helmet} from 'react-helmet';
import SmartBanner from 'react-smartbanner';
import '../../../node_modules/react-smartbanner/dist/main.css';
import {inline_js,external_js} from "../../io/common";
import tr from '../MultLanguage/tr.json';
import en from '../MultLanguage/en.json';
import de from '../MultLanguage/de.json';
import es from '../MultLanguage/es.json';
import fr from '../MultLanguage/fr.json';
import lt from '../MultLanguage/lt.json';
import ru from '../MultLanguage/ru.json';
import soundfile from '../../img/restaurantOrder.mp3';
const audio = new Audio(soundfile);

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'
switch (localStorage.getItem('Language') || 'en') {
	case "tr":
		setTranslations({ tr })
		setDefaultLanguage(language.toString())
		break;
	case "fr":
		setTranslations({ fr })
		setDefaultLanguage(language.toString())
		break;
	case "es":
		setTranslations({ es })
		setDefaultLanguage(language.toString())
		break;
	case "ru":
		setTranslations({ ru })
		setDefaultLanguage(language.toString())
		break;
	case "lt":
		setTranslations({ lt })
		setDefaultLanguage(language.toString())
		break;
	case "de":
		setTranslations({ de })
		setDefaultLanguage(language.toString())
		break;
	default:
		setTranslations({ en })
		setDefaultLanguage('en')

}

const mapStateToProps = state => {
	return { user: state.user };
};

const mapDispatchToProps = dispatch => {
	return {
		userLogin: user => dispatch(userLogin(user))
	};
};

const Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000
});


class Header extends Component {

	constructor(props) {

		super(props);

		this.state = {
			store_user: this.props.user || {},
			dropdownOpen: false,
			isCollasedOpen: false,
			toggleButton: false,
			loginData: {
			},
			cartItems: 0,
			unreadMessageCount: 0,
			openProfileDropDown: false,
			currency: '',
			language: localStorage.getItem('Language') || 'en',
			country: '',
			foodmanimg: require("../../static/img/logoOne.png"),
			toggleAddress: false,
			address: '',
			userId: '',
			token: '',
			isTop: true, scrollPosition: "0",
			lat: localStorage.getItem('location') && JSON.parse(localStorage.getItem('location')).lat,
			lon: localStorage.getItem('location') && JSON.parse(localStorage.getItem('location')).lon,
		};

		this.isLogin = localStorage.getItem('IsLogin');
		console.log(this.isLogin,'isLogin')
		this.pubnub = new PubNubReact({
			publishKey: config.pubnubPublishKey,
			subscribeKey: config.pubnubSubscribeKey
		});
		this.pubnub.init(this);
		this.saveUserDataInStore = this.saveUserDataInStore.bind(this);
		this.onToggleButton = this.onToggleButton.bind(this);
		this.profileDropDown = this.profileDropDown.bind(this);
		this.toggleProfileDropDown = this.toggleProfileDropDown.bind(this);

		// console.log(this.state.isLogin)
	}

	closePopup = event => {
		// console.log(event.currentTarget);
		var cT = event.currentTarget;
		cT.parentNode.remove();
	}

	componentDidMount() {
		external_js("https://www.googletagmanager.com/gtag/js?id=G-6HSETJTMGE");
		inline_js(`
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', 'G-6HSETJTMGE');
			gtag('config', 'AW-449064384');
		`);

		const user = this.props.user;
		let lat = localStorage.getItem('location') && JSON.parse(localStorage.getItem('location')).lat || '';
		let lon = localStorage.getItem('location') && JSON.parse(localStorage.getItem('location')).lon || '';
		var userData = JSON.parse(localStorage.getItem('userData'));
		var token = localStorage.getItem('Token');
		this.setState({
			token: token ? token : "",
			lat: lat,
			lon: lon,
			userId: userData ? userData._id : "",
		}, () => {
			Geocode.setApiKey(apiUrl.google_Api_Key);
			Geocode.setLanguage("en");
			// Get address from latidude & longitude.
			Geocode.fromLatLng(this.state.lat, this.state.lon).then(
				response => {
					const address = response.results[0].formatted_address;
					this.setState({
						address: address
					})
				},
				error => {
					console.error("...", error);
				}
			);
		});
		if (!isEmpty(userData)) {
			this.pubnub.subscribe({
				channels: [userData._id],
				withPresence: true
			});
			this.pubnub.getMessage(userData._id, (resP) => {
				const location = window.location.pathname;
				document.getElementById("manish").click(audio.play())
				this.setState({alertSound:true});
				if (!isEmpty(resP) && location !== '/messages') {
					this.setState({ unreadMessageCount: (this.state.unreadMessageCount + 1) })
				}
				if (!isEmpty(resP) && location === '/messages') {
					this.setState({ unreadMessageCount: 0 })
				}

			});
		}
		// window.onscroll = () => {
		//	 var d = document.documentElement;
		//	 var offset = d.scrollTop + window.innerHeight;
		//	 var height = d.offsetHeight;
		//	 if (offset >= height) {
		//		 this.setState({ isTop: false })
		//	 } else {
		//		 this.setState({ isTop: true })
		//	 }
		// }
	}

	componentWillUnmount() {
		if (!isEmpty(this.state.userId)) {
			this.pubnub.unsubscribe({
				channels: [this.state.userId]
			});
		}
		this.setState({ isTop: true, })
	}


	onToggleButton() {
		var tog = this.state.toggleButton == true ? false : true
		this.setState({ toggleButton: tog });
	}

	static getDerivedStateFromProps(props, state) {
		if (state.store_user !== props.user) {
			return {
				store_user: props.user,
			}
		}
		return null;
	}

	saveUserDataInStore(userData) {
		if (!isEmpty(userData)) {
			this.props.userLogin(userData);
		}
	}

	toggleProfileDropDown() {
		this.setState({ openProfileDropDown: !this.state.openProfileDropDown });
	}

	profileDropDown() {
		return (<ButtonDropdown isOpen={this.state.openProfileDropDown} toggle={this.toggleProfileDropDown}>
			<DropdownToggle color="light">
				<div className="head_profile" onClick={this.profileDropDown}>
					<span className="user_name">{capitalize((this.props.user.first_name) || '') || this.props.user.mobile || ''}</span> <span className="head_profile_img"><img src={(this.props.user.user_image) || ''} alt="" /></span>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem><Link to="/profile">Edit Profile</Link></DropdownItem>
				<DropdownItem><Link to="/password">Manage Password</Link></DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>)
	}

	toggleAddress = () => {
		this.setState({ toggleAddress: !this.state.toggleAddress });
	}

	scrollToggle = () => {
		if (this.state.isTop) {
			window.scroll(0, document.body.scrollHeight)
		}
		else {
			window.scrollTo(0, 0)
		}
		this.setState({ isTop: !this.state.isTop })

	}

	updateLanguage = async () => {
		let postJson = {
			language: this.state.language, user_id: this.state.userId
		};
		let path = apiUrl.change_Language;
		const fr = await Helper.post(postJson, path, this.state.token);
		const res = await fr.response.json();
		if (fr.status === 200) {
			if (res.success) {
				console.log("res.result", res.result)
				Toast.fire({
					type: "success",
					title: res.msg,
				})
			} else {
				Toast.fire({
					type: "error",
					title: res.msg,
				});
			}
		} else {
			Toast.fire({
				type: "error",
				title: res.error,
			});

		}
	}


	handleSelect = address => {
		this.setState({ address: address})
		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng =>
				this.setState({
					location: {
						lat: latLng.lat,
						lon: latLng.lng
					},
					lat: latLng.lat,
					lon: latLng.lng
				}, () => {
					localStorage.setItem('location', JSON.stringify(this.state.location))
				}),
			)
			.catch(error => console.error('Error', error));
	};

	handleChange = address => {
		this.setState({
			address: address,
		});
	};


	render() {
		const headerClass = (params) => {
			switch (params) {
				case '/':
				case '/register':
				case '/home':
					return '';
				default:
					return 'inner-header';
			}
		}
		const arrowHidden = (goback) => {
			switch (goback) {
				case '/':
				// case '/register':
				case '/home':
				case '/login':
					return 'arrowhidden';
				default:
					return '';
			}
		}

		const addressHidden = (address) => {
			switch (address) {
				case '/':
				case '/register':
				case '/home':
				case '/login':
					return 'd-none';
				default:
					return 'locationHeaderuser';
			}
		}
		const { t } = this.props
		const messages = this.pubnub.getMessage(this.state.userId);
		console.log('messages',messages)
		return (
			<header className={headerClass(window.location.pathname) +' newClassHeader'}>
				<Helmet>
					<link rel="android-touch-icon" href={require("../../static/img/app.png")} />
					<link rel="apple-touch-icon" href={require("../../static/img/app.png")} />
				</Helmet>
				<div className="container">
					<div className="row">
						<div style={{position:'absolute', right:'5px', bottom:'6px', zIndex:'1000'}}>
							{messages.map((m, index) => <p className={"nidhi"} style={{position:'relative', boxShadow: '0px 6px 24px 0px rgba(0,0,0,0.2)', backgroundColor: '#ff8117', border: '0 solid #ff8117', padding:'15px', borderRadius:'5px'}} key={'message' + index}><span style={{position: 'absolute', right: '5px', top: '0', color: '#fff', cursor:'pointer'}} onClick={this.closePopup}>x</span><span className="notranslate">{m.message.message}</span></p>)}
						</div>
						<div className="col-3 col-sm-4 col-md-2 col-lg-2 pr-0 pl-2">
							<div className="logo" data-aos="fade-right" data-aos-duration="2000">
								<a className={arrowHidden(window.location.pathname)} href="javascript: history.go(-1)"><i className="fa fa-chevron-left" aria-hidden="true"></i></a>
								{window.location.pathname !== '/register' &&
									<Link to="/" className="nav-link">
										<img src={this.state.foodmanimg} alt="Here is Food"
											onMouseEnter={() => {
												this.setState({
													foodmanimg: require("../../static/img/logoOnehover.png")
												})
											}}
											onMouseOut={() => {
												this.setState({
													foodmanimg: require("../../static/img/logoOne.png")
												})
											}}
										/>
									</Link>}

							</div>
						</div>
						<div className="col-2 col-sm-2 col-md-6 col-lg-4 pr-0">
							{this.state.lat !== null &&
								<div className={addressHidden(window.location.pathname)}>
									<span className="mobilelocation" onClick={(e) => {
										this.toggleAddress();
									}}><i className="fa fa-map-marker-alt"></i></span>
									<a href="javascript:void(0);" title={t("resd.Address")} onClick={(e) => {
										this.toggleAddress();
									}}>
										<i className="fa fa-map-marker-alt"></i> {this.state.address}</a>
								</div>}
						</div>
						<div className="col-6 col-sm-6 col-md-4 col-lg-6 pr-0">
							<div className="right-header">
								<div className="user-info-header">
									{/* <span className="location">
										<div className="dropdown">
											<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span><img src={require("../../static/img/location-icon.png")} alt="#" />Istanbul</span></button>
											<select type="text" className="custom-select" value={this.state.country}>
												<option value="istanbul">Istanbul</option>
												<option value="us">Us</option>
												<option value="uk">Uk</option>
												<option value="india">India</option>
											</select>
										</div>
									</span> */}
									{/* <span className="location">
										<div className="dropdown">
											<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span><img src={require("../../static/img/flag-icon.png")} alt="#" /></span>Euro</button>
											<select type="text" className="custom-select" value={this.state.currency}>
												<option value="tl">Tl</option>
												<option value="euro">Euro</option>
												<option value="usd">Usd</option>
											</select>

										</div>
									</span> */}
									<span className="location">
										<div className="dropdown">
											<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span><i className="fa fa-language" aria-hidden="true"></i></span></button>
											<select type="text" className="custom-select" value={this.state.language}
												onChange={(e) => {
													this.setState({
														language: e.target.value
													}, () => { this.updateLanguage() })
													localStorage.setItem('Language', e.target.value)
													window.location.reload()
												}}>
												<option value="en">English</option>
												<option value="tr">Turkish</option>
												<option value="ru">Russian</option>
												<option value="es">Spanish</option>
												{/* <option value="de">German</option>
												<option value="fr">French</option>
												<option value="lt">Lithuanian</option> */}
											</select>
										</div>
									</span>
								</div>
								<div className="main-menu">
									<nav className="navbar navbar-expand-lg navbar-light bg-light">
										<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
											aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={(e) => {
												this.onToggleButton();
											}}>
											<span className="navbar-toggler-icon">
												<i className="fas fa-bars"></i>
											</span>
										</button>

										<div className={this.state.toggleButton === false ? "collapse navbar-collapse" : "collapse navbar-collapse show"} id="navbarSupportedContent" onClick={(e) => {
												this.onToggleButton();
											}}>
											<ul className="navbar-nav mr-auto">
												{this.isLogin === null && <li className="nav-item active">
													<Link to="/login" className="nav-link">{t("login.Login")}</Link>
												</li>}
												{this.isLogin === null && <li className="nav-item">
													<Link to="/register" className="nav-link">{t("header.Sign Up")}</Link>
												</li>}
												{this.isLogin !== null && <li className="nav-item">
													<Link to="/user-profile">{t("header.Profile")}</Link>
												</li>}
												<li className="nav-item">
													<Link className="nav-link" to="/contact">{t("header.Contact Us")}</Link>
												</li>
												{this.isLogin !== null && <li className="nav-item">
													<a href="javascript:Void(0)" onClick={e => this.props.onLogout(e)}>{t("header.Logout")}</a>
												</li>}
											</ul>
										</div>
									</nav>
									<button id="manish" style={{"visibility":"hidden"}}>.</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Modal */}
				<div className={this.state.toggleAddress === false ? "modal Customization_modal fade locationmodule" : "modal Customization_modal fade locationmodule show modalOpenBox"} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">{t("resd.Address")}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
									this.setState({
										toggleAddress: false
									})
									window.location.reload()
								}}><span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body mapOuter addressOuterMap" >
								<PlacesAutocomplete
									value={this.state.address}
									onChange={this.handleChange}
									onSelect={this.handleSelect} >
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										<div>
											<input
												{...getInputProps({
													placeholder: t('home.Search Your Places'),
													className: 'location-search-input',
												})}
											/>
											<div className="autocomplete-dropdown-container">
												{loading && <div>Loading...</div>}
												{suggestions.map(suggestion => {
													const className = suggestion.active
														? 'suggestion-item--active'
														: 'suggestion-item';
													// inline style for demonstration purpose
													const style = suggestion.active
														? { backgroundColor: '#fafafa', cursor: 'pointer' }
														: { backgroundColor: '#ffffff', cursor: 'pointer' };
													return (
														<div
															{...getSuggestionItemProps(suggestion, {
																className,
																style,
															})}
														>
															<span>{suggestion.description}</span>
														</div>
													);
												})}

											</div>
											{/* <div className="centerBtn text-center">
												<button className="btn" onClick={(e) => {
													if (this.state.address === '') {
														this.setState({
															isaddress: true
														})
													} else {
														this.setState({
															toggleAddress: false
														})
														window.location.reload()
													}

												}}>{t("home.Submit")}</button>
											</div> */}
										</div>
									)}
								</PlacesAutocomplete>
								<iframe src={`https://www.google.com/maps/embed/v1/place?key=${apiUrl.google_Api_Key}&q=${this.state.lat},${this.state.lon}`} width="600" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe>
							</div>
						</div>
					</div>
				</div>
				<div className={this.state.toggleAddress === true ? "modal-backdrop fade show" : ""} onClick={(e) => {
					e.preventDefault();
					this.setState({ toggleAddress: false })
				}}></div>
				{this.state.isTop === true && <a href="javascript:void(0);" onClick={(e) => this.scrollToggle()}><div className="cartOuter">
					<img src={require("../../static/img/arrowdown-1.png")} alt="" />
				</div></a>}
				{this.state.isTop === false && <a href="javascript:void(0);" onClick={(e) => this.scrollToggle()}><div className="cartOuter">
					<img src={require("../../static/img/arrowup-1.png")} alt="" />
				</div></a>}
				<SmartBanner title={"Here is Food"} position={'bottom'} daysHidden={1} daysReminder={10} />
			</header>
		)
	}
}
export default (mapStateToProps, mapDispatchToProps, withTranslation)(Header);
