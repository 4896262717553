import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Input, Button, ModalBody, Modal } from 'reactstrap';
import queryString from 'query-string';

import { isEmpty, isInteger } from 'lodash';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";

class ContactUs extends Component {
	
	render() {

		return <section>
			<section className="inner_slide">
				<img src="https://urentme.com//app/webroot/img/front_home/images/contact_img.jpg" alt="contact_head" className="img-fluid" />
			</section>
			<section className="contact_info">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-4">
						<div className="content_inner_info">
							<h2>Contact us</h2>
							<p>GapWallet <br />2111 S 67th St Ste 300 </p>
							<ul>
							<li>
								<p>
								<span><i className="fas fa-headset"></i></span> (402) 999-0800, (800) 599-1330 </p>
							</li>
							<li>
								<p>
								<span><i className="fas fa-map-marker-alt"></i></span> Omaha, NE 68106 </p>
							</li>
							<li>
								<p>
								<span><i className="fas fa-envelope"></i></span> hello@mygapwallet.com</p>
							</li>
							<li>
								<p>
								<span><i className="fas fa-envelope"></i></span> lspsupport@mygapwallet.com</p>
							</li>
						</ul>
						<ul className="social-link">
							<li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
							<li><a href="#"><i className="fab fa-twitter"></i></a></li>
							<li><a href="#"><i className="fab fa-youtube"></i></a></li>
							<li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>				
						</ul>
						</div>
						</div>
						<div className="col-12 col-sm-12 col-md-8">
						<div className="map_outer">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.5168511576453!2d75.81251641501841!3d26.855315369023838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db6747842586d%3A0x205bc4e86332fa4!2sOctal+IT+Solution!5e0!3m2!1sen!2sin!4v1548065196032"  frameBorder="0" style={{"border":"0"}} allowfullscreen></iframe>
						</div>
						</div>
					</div>
				</div>
				</section>
				<section className="contact_form">
				<div className="container-fluid">
					<div className="row">
					<div className="col-md-12">
						<form>
						<div className="row">
							<div className="col-12 col-sm-6 col-md-4">
							<div className="form-group">
								<label>First Name</label>
								<input type="text" name="" className="form-control" placeholder="First Name" />
							</div>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
							<div className="form-group">
								<label>Last Name</label>
								<input type="text" name="" className="form-control" placeholder="Last Name" />
							</div>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
							<div className="form-group">
								<label>Email</label>
								<input type="email" name="" className="form-control" placeholder="Email" />
							</div>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
							<div className="form-group">
								<label>Country Code</label>
								<select className="custom-select" id="">
									<option selected>United States(+1)</option>
								</select>
							</div>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
							<div className="form-group">
								<label>Mobile</label>
								<input type="text" name="" className="form-control" placeholder="Mobile" />
							</div>
							</div>
							<div className="col-12 col-sm-12 col-md-12">
							<div className="form-group">
								<label>Comments</label>
								<textarea className="form-control" id="" rows="8" placeholder="Comment"></textarea>
							</div>
							</div>
							<div className="col-12 col-sm-12 col-md-12">
							<div className="submit_type_btn">
								<input className="btn" type="submit" value="Submit" />
							</div>
							</div>
						</div>
						</form>
					</div>
					</div>
				</div>
			</section>
			</section>
		

	}

		
}


export default ContactUs;

