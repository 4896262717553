import React, { Component } from 'react';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, capitalize } from "lodash";
import PubNubReact from 'pubnub-react';
import connect from "react-redux/es/connect/connect";
//import { connect } from 'react-redux';
import { userLogin } from "../../actions/index";
import config from "../../constants/config";

class HeaderLogin extends Component {

	constructor(props) {

		super(props);

		this.state = {
			store_user: this.props.user || {},
			dropdownOpen: false,
			isCollasedOpen: false,
			isLogin: false,
			toggleButton: false,
			language: localStorage.getItem('Language') || 'en',
			loginData: {
			},
			cartItems: 0,
			unreadMessageCount: 0,
			openProfileDropDown: false
		};
		this.pubnub = new PubNubReact({
			publishKey: config.pubnubPublishKey,
			subscribeKey: config.pubnubSubscribeKey
		});
		this.pubnub.init(this);
		this.saveUserDataInStore = this.saveUserDataInStore.bind(this);
		this.onToggleButton = this.onToggleButton.bind(this);
		this.profileDropDown = this.profileDropDown.bind(this);
		this.toggleProfileDropDown = this.toggleProfileDropDown.bind(this);


	}

	componentDidMount() {

		const user = this.props.user;
		if (!isEmpty(user)) {

			this.pubnub.subscribe({
				channels: [user._id],
				withPresence: true
			});
			this.pubnub.getMessage(user._id, (resP) => {
				const location = window.location.pathname;
				if (!isEmpty(resP) && location !== '/messages') {
					this.setState({ unreadMessageCount: (this.state.unreadMessageCount + 1) })
				}
				if (!isEmpty(resP) && location === '/messages') {
					this.setState({ unreadMessageCount: 0 })
				}

				this.setState({});

			});
		}


	}
	componentWillUnmount() {
		const userId = this.props.user && this.props.user._id;
		if (!isEmpty(userId)) {

			this.pubnub.unsubscribe({
				channels: [this.props.user._id]
			});
		}

	}

	onToggleButton() {
		this.setState({ toggleButton: !this.state.toggleButton });
	}

	static getDerivedStateFromProps(props, state) {
		//console.log('getDerivedStateFromProps from header', props.user, state.store_user)
		if (state.store_user !== props.user) {

			//console.log('inside ')

			return {

				store_user: props.user,

			}
		}

		return null;
	}

	saveUserDataInStore(userData) {
		if (!isEmpty(userData)) {
			this.props.userLogin(userData);
		}

	}

	toggleProfileDropDown() {

		this.setState({ openProfileDropDown: !this.state.openProfileDropDown });

	}

	profileDropDown() {

		return (<ButtonDropdown isOpen={this.state.openProfileDropDown} toggle={this.toggleProfileDropDown}>
			<DropdownToggle color="light">
				<div className="head_profile" onClick={this.profileDropDown}>
					<span className="user_name">{capitalize((this.props.user.first_name) || '') || this.props.user.mobile || ''}</span> <span className="head_profile_img"><img src={(this.props.user.user_image) || ''} alt="" /></span>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem><Link to="/profile">Edit Profile</Link></DropdownItem>
				<DropdownItem><Link to="/password">Manage Password</Link></DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>)

	}


	render() {

		return (
			<header class="login-header">
				<a href="javascript: history.go(-1)" className="loginback"><i className="fa fa-chevron-left" aria-hidden="true"></i></a>
				<div class="container">
					<div class="row">
						<div class="col-3 col-sm-5 col-md-3 pr-0 pl-2">
						</div>
						<div class="col-8 col-sm-7 col-md-9 pr-0">
							<div class="right-header">
								<div class="user-info-header">
									{/* <span class="location">
										<div class="dropdown">
											<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span><img src={require("../../static/img/location-icon.png")} alt="#" />Istanbul</span></button>

											<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
												<a class="dropdown-item" href="#">India</a>
												<a class="dropdown-item" href="#">Uk</a>
												<a class="dropdown-item" href="#">Us</a>
											</div>
										</div>
									</span>
									<span class="location">
										<div class="dropdown">
											<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span><img src={require("../../static/img/flag-icon.png")} alt="#" /></span>Euro</button>
											<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
												<a class="dropdown-item" href="#">India</a>
												<a class="dropdown-item" href="#">Uk</a>
												<a class="dropdown-item" href="#">Us</a>
											</div>
										</div>
									</span> */}
									<span class="location">
										<div class="dropdown">
											<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span><i className="fa fa-language" aria-hidden="true"></i></span></button>
											<select type="text" className="custom-select" value={this.state.language}
												onChange={(e) => {
													this.setState({
														language: e.target.value
													})
													localStorage.setItem('Language', e.target.value)
													window.location.reload()
												}}>
												<option value="en">English</option>
												<option value="tr">Turkish</option>
												<option value="ru">Russian</option>
												<option value="es">Spanish</option>
												{/* <option value="de">German</option>
												<option value="fr">French</option>
												<option value="lt">Lithuanian</option> */}
											</select>
										</div>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		)
	}
}

const mapStateToProps = state => {
	return { user: state.user };
};

const mapDispatchToProps = dispatch => {
	return {
		userLogin: user => dispatch(userLogin(user))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogin);
