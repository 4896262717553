var inline_js=function(code,to_head=true) {
	// Embed an inline script tag. Call this in componentDidMount().
	// WARNING: this will not work if CSP is enforced unless you allow 'unsafe-inline'.
	var tag=document.createElement('script')
	tag.type='text/javascript';
	try {
		tag.appendChild(document.createTextNode(code));
	} catch (e) {
		tag.text=code;
	}
	if (to_head) {
		document.head.appendChild(tag);
	} else {
		document.body.appendChild(tag);
	}
}

var external_js=function(url,async=true,to_head=true) {
	// Add an external script tag (with src). Call this in componentDidMount().
	var tag=document.createElement('script')
	tag.type='text/javascript';
	tag.async=async;
	tag.src=url;
	if (to_head) {
		document.head.appendChild(tag);
	} else {
		document.body.appendChild(tag);
	}
}

export {inline_js,external_js}
