import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Input, Button, ModalBody, Modal } from 'reactstrap';
import queryString from 'query-string';

import { isEmpty, isInteger } from 'lodash';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";


import AboutUs from './AboutUs/AboutUs';
import Features from './Features/Features';
import TermsConditions from './TermsConditions/TermsConditions';
import Support from './Support/Support';
import ContactUs from './ContactUs';


class More extends Component {

	constructor(props){

		super(props);

		this.state = {

			link:  'aboutus'

		};

	}

	componentDidMount(){

		let location = (this.props.match && this.props.match.params && this.props.match.params.link) || {};
		if(!isEmpty(location)) {

			this.setState({ link: location });

		}

	}

	static getDerivedStateFromProps(props, state){

		/* let query = queryString.parse(props.location && props.location.search);
		let location = query.link || ''; */
		let location = (props.location && props.location.pageData && props.location.pageData.link) || '';
		if(!isEmpty(location)) {

			if((location) !== state.link){
				
				return {
					link: props.location.pageData.link
				}
	
			} else {
				return null;
			}

		}

		 else {

			return null;

		}

	}

	/* componentDidMount(){

		this.setState({ loading: true });
		this.getVehicleList();

	}

	async getVehicleList(){

		const apiPath = `${pathObj.baseURL}${pathObj.vehicleList}`;
		const options = {
			headers:{
				'x-access-token': this.props.user && this.props.user.token 
			}
		};
		let response = {};
		let responseData = {};
		try{
			response = await fetch(apiPath, options);
			responseData = await response.json();
		} catch(err){

		}
		if(response.status === 200) {
			this.setState({vehicleList: responseData.result, loading: false});
		}

	} */

	render() {

		if(this.state.link === 'contactus') {

			return <ContactUs />

		}

		return (
		
			<div className="main terms-conditons">
				<div className="container">
					<div className="row">
						<div className="col-md-12">

							<div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
								<span className="links">Quick Links:</span>
								
								<Link to={{ pathname:"/more/aboutus", pageData: { link: 'aboutus' }}} className={`nav-link ${this.state.link === 'aboutus' && 'active'}`}  id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">About us</Link>
								<Link to={{ pathname:"/more/features", pageData: { link: 'features' }}} className={`nav-link ${this.state.link === 'features' && 'active'}`}  id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Features</Link>
								<Link to={{ pathname:"/more/support", pageData: { link: 'support' }}} className={`nav-link ${this.state.link === 'support' && 'active'}`}  id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Support</Link>
								<Link to={{ pathname:"/more/termsconditions", pageData: { link: 'termsconditions' }}} className={`nav-link ${this.state.link === 'termsconditions' && 'active'}`}  id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Terms &amp; Conditions</Link>
								
							</div>
							<div className="tab-content" id="v-pills-tabContent">
							
								{this.state.link === 'aboutus' && <AboutUs />}
								{this.state.link === 'features' && <Features />}
								{this.state.link === 'support' && <Support /> }
								{this.state.link === 'termsconditions' && <TermsConditions />}
							
							</div>
						</div>
					</div>
				</div>
			</div>	 
		)
	}
}


export default More;

