// src/js/reducers/index.js
import { USER_LOGIN, USER_LOGOUT, LSP_LIST, CHAT_LIST } from "../constants/action-types";
const initialState = {
	user: {},
	lspDataList: []
};
const rootReducer = (state = initialState, action) => {
	switch (action.type) {
		case USER_LOGIN:
			return { ...state, user: action.payload };
		case USER_LOGOUT:
			return { ...state, lspList: action.payload, chatList: action.payload, user: action.payload,  };	
		case LSP_LIST:
			return { ...state, lspList: action.payload };
		case CHAT_LIST:
			return { ...state, chatList: action.payload };
		default:
			return state;
	}

};
export default rootReducer;