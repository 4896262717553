import React, { Component } from 'react';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import tr from '../MultLanguage/tr.json';
import en from '../MultLanguage/en.json';
import de from '../MultLanguage/de.json';
import es from '../MultLanguage/es.json';
import fr from '../MultLanguage/fr.json';
import lt from '../MultLanguage/lt.json';
import ru from '../MultLanguage/ru.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'
switch (localStorage.getItem('Language') || 'en') {
	case "tr":
		setTranslations({ tr })
		setDefaultLanguage(language.toString())
		break;
	case "fr":
		setTranslations({ fr })
		setDefaultLanguage(language.toString())
		break;
	case "es":
		setTranslations({ es })
		setDefaultLanguage(language.toString())
		break;
	case "ru":
		setTranslations({ ru })
		setDefaultLanguage(language.toString())
		break;
	case "lt":
		setTranslations({ lt })
		setDefaultLanguage(language.toString())
		break;
	case "de":
		setTranslations({ de })
		setDefaultLanguage(language.toString())
		break;
	default:
		setTranslations({ en })
		setDefaultLanguage('en')

}

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			foodsection: localStorage.getItem('Foodsection') || "0",
		}
	}
	render() {
		const { t } = this.props
		const { foodsection } = this.state
		return (
			<footer className="">
				<img src={foodsection === "0" ? require("../../static/img/footer-bg.png") : require("../../static/img/tablefood-3.png")} alt="#" className="footer-bottom" />
				<div className="">
					<div className="container">
						<div className="row">
							<div className="col-12 col-sm-6 col-md-3">
								<div className="footer-links">
									<ul>
										<li><a href="/user_agreement">{t("footer.User Agreement")}</a></li>
										<li><a href="/delivery_return_policy">{t("footer.Delivery And Return Policy")}</a></li>
										<li><a href="/faq">{t("footer.Frequently asked questions")}</a></li>
										<li><a href="/promotion_terms">{t("footer.Promotion Terms")}</a></li>
										{/* <li><a href="/partnership_restaurants">Partnership For Restaurants</a></li> */}
									</ul>
								</div>
							</div>
							<div className="col-12 col-sm-6 col-md-3">
								<div className="footer-links">
									<ul>
										<li><a href="/help">{t("footer.Help")}</a></li>
										<li><a href="/contact">{t("header.Contact Us")}</a></li>
										<li><a href="/how_it_works">{t("footer.How it works")}</a></li>
										{/* <li><a href="/terms-conditions">Terms and Conditions</a></li> */}
										<li><a href="/privacy-policy">{t("footer.Privacy Policy")}</a></li>
										<li><a href="https://restaurant.hereisfood.net/" target="_blank">{t("footer.Restaurant Login")}</a></li>
										{/* <li><a href="#">Delivery Boy Login</a></li> */}
									</ul>
								</div>
							</div>
							{/* <div className="col-12 col-sm-6 col-md-3">
								<div className="footer-links">
									<ul>
										<li><a href="/list_your_business">List your Business</a></li>
										<li><a href="/promotions">Promotions</a></li>
										<li><a href="/latest_news_updates">Latest News/Updates</a></li>
									</ul>
								</div>
							</div> */}
							<div className="col-12 col-sm-6 col-md-3">
								<div className="footer-links">
									<h3>{t("footer.Join Us")}</h3>
									<ul className="social-list">
										<li><a href="https://www.instagram.com/hereisfood.group/" target="_blank"><img src={require("../../static/img/instra.png")} alt="#" /> </a></li>
										<li><a href="https://www.facebook.com/Here-is-Food-106008471430799" target="_blank"><img src={require("../../static/img/fb.png")} alt="#" /> </a></li>
									</ul>
								</div>
							</div>
							<div className="col-md-12">
								<p className="copy-right">
									©2020  Here is Food. {t("footer.All rights Reserved")}.
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

export default withTranslation(Footer);
