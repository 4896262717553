import React, { Component } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import Helper from '../../constants/helper';
import Categories from '../Home/Categories';
import RestaurantsList from '../Home/RestaurantList';
import TableRestaurantsList from '../Home/TableResturantList';
import DeliveryProcess from '../Home/DeliveryProcess';
import TableDeliveryProcess from '../Home/TableDeliveryProcess';
import Promotion from '../Home/Promotion';
import Swal from 'sweetalert2';
import apiUrl from '../../constants/apiPath';
import Loader from '../../views/Common/Loader';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import tr from '../MultLanguage/tr.json';
import en from '../MultLanguage/en.json';
import de from '../MultLanguage/de.json';
import es from '../MultLanguage/es.json';
import fr from '../MultLanguage/fr.json';
import lt from '../MultLanguage/lt.json';
import ru from '../MultLanguage/ru.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'
switch (localStorage.getItem('Language') || 'en') {
	case "tr":
		setTranslations({ tr })
		setDefaultLanguage(language.toString())
		break;
	case "fr":
		setTranslations({ fr })
		setDefaultLanguage(language.toString())
		break;
	case "es":
		setTranslations({ es })
		setDefaultLanguage(language.toString())
		break;
	case "ru":
		setTranslations({ ru })
		setDefaultLanguage(language.toString())
		break;
	case "lt":
		setTranslations({ lt })
		setDefaultLanguage(language.toString())
		break;
	case "de":
		setTranslations({ de })
		setDefaultLanguage(language.toString())
		break;
	default:
		setTranslations({ en })
		setDefaultLanguage('en')

}


const Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000
});

class Home extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		this.state = {
			leftclass : localStorage.getItem('leftclass') || '',
			rightclass : localStorage.getItem('rightclass') || '',
			visible: false,
			foodmanimg: require("../../static/img/food-man.png"),
			PromotionData: [], keywords: '', systemIpAddress: '',
			location: {
				lat: "",
				lon: ""
			},
			DefaultData: [], default_Address: {}, userId: '', token: '',
			cust_Currency_Value: '', default_Currency: '',
			toggleAddress: false, address: '', isaddress: false, currencyArray: [],
			language: localStorage.getItem('Language') || 'en',
			foodsection: localStorage.getItem('Foodsection') || "0", addlocation: ""
		}
	}

	componentDidMount() {
		var token = localStorage.getItem('Token');
		var userData = JSON.parse(localStorage.getItem('userData'));
		var location = localStorage.getItem('location');
		var curData = JSON.parse(localStorage.getItem('CurrencyArray'))
		console.log('curData',curData,'userData',userData);
		this.setState({
			addlocation: location,
			token: token ? token : "",
			userId: userData ? userData._id : "",
			default_Currency: userData ? userData.default_currency : "USD",
		}, () => {
			/*if(curData == null){*/
				this.getCurrency()
			/*}else{
				this.setState({
					currencyArray: curData,
				})
			}*/
		})
		if (userData) {
			this.getDefaultData(token, userData._id)
		} else {
			// this.getIpAddress();
			// this.getLatLog();
			if (location === null) {
				this.setState({
					toggleAddress: true
				})
			}

		}
		localStorage.setItem("Foodsection", this.state.foodsection)
		localStorage.removeItem("RestaurantsData")
	}

	scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);

	getCurrency = async () => {
		this.setState({
			visible: true
		})
		let postJson = {
			language: this.state.language, user_id: this.state.userId
		};
		let path = apiUrl.get_Currency_Value;
		const fr = await Helper.post(postJson, path);
		const res = await fr.response.json();
		if (fr.status === 200) {
			if (res.success) {
				this.setState({
					currencyArray: res.result,
					visible: false
				});
				localStorage.setItem('CurrencyArray', JSON.stringify(res.result))
			} else {
				this.setState({
					visible: false
				})
				Toast.fire({
					type: "error",
					title: res.msg,
				});
			}
		} else {
			this.setState({
				visible: false
			})
			Toast.fire({
				type: "error",
				title: res.error,
			});

		}
		this.setState({
			visible: false
		})
	}

	getDefaultData = async (token, userid) => {
		let postJson = {
			language: this.state.language, userId: userid
		};
		let path = apiUrl.get_Default_Address;
		const fr = await Helper.post(postJson, path, token);
		const res = await fr.response.json();
		if (fr.status === 200) {
			if (res.success) {
				this.setState({
					DefaultData: res.result,
					default_Address: res.result.default_Address
				}, () => {
					if (Object.keys(this.state.default_Address).length !== 0) {
						// this.setState({
						//	 location: {
						//		 lat: this.state.default_Address.latitude,
						//		 lon: this.state.default_Address.longitude
						//	 }
						// }, () => {
						//	 localStorage.setItem('location', JSON.stringify(this.state.location))
						// })
					} else {
						// this.getIpAddress();
						// this.getLatLog();
						if (localStorage.getItem('location') === null) {
							this.setState({
								toggleAddress: true
							})
						}

					}
				})

			} else {
				// Toast.fire({
				//	 type: "error",
				//	 title: res.msg,
				// });
			}
		} else {
			// Toast.fire({
			//	 type: "error",
			//	 title: res.msg,
			// });

		}
	}

	onChange = (e) => {
		this.setState({
			keywords: e.target.value
		})
	}

	getIpAddress = async () => {
		const fr = await Helper.getip('https://api.ipify.org/?format=json');
		const res = await fr.response.json();
		if (fr.status === 200) {
			// var geo = geoip.lookup(res.ip);
			this.setState({
				systemIpAddress: res.ip
			})
			this.getLatLog(res.ip)
		} else {
			Toast.fire({
				type: "error",
				title: res.error,
			});
		}
	}

	getLatLog = (ip) => {
		if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
			navigator.geolocation.getCurrentPosition((position) => {
				var lat = position.coords.latitude
				var lon = position.coords.longitude
				this.setState({
					location: {
						lat: lat,
						lon: lon
					}
				}, () => {
					localStorage.setItem('location', JSON.stringify(this.state.location))
				})
				// save location by sending to server
			}, () => {
				this.getLatLogWithIp(ip);
				// alert("Couldn't get your Location!");
			});
		}
	}

	getLatLogWithIp = async (ipadd) => {
		const fr = await fetch('http://ip-api.com/json/' + `${ipadd}`);
		const res = await fr.json();
		if (fr.status === 200) {
			var lat = res.lat
			var lon = res.lon
			this.setState({
				location: {
					lat: lat,
					lon: lon
				}
			})
			localStorage.setItem('location', JSON.stringify(this.state.location))
		} else {
			Toast.fire({
				type: "error",
				title: res.error,
			});
		}
	}

	handleSelect = address => {
		this.setState({ address: address, isaddress: false, })
		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng =>
				this.setState({
					location: {
						lat: latLng.lat,
						lon: latLng.lng
					}
				}, () => {
					localStorage.setItem('location', JSON.stringify(this.state.location))
				}),
			)
			.catch(error => console.error('Error', error));
	};

	handleChange = address => {
		this.setState({
			address: address,
			isaddress: true
		});
	};


	render() {
		const { t } = this.props
		return (
			<div>
				<Loader visible={this.state.visible} />
				<section className="twine_slider">
					<div className="twin-hero">
						<div className="hero-shadow"></div>
						<div className="hero-container">
							<div className={"left-hero "+ this.state.leftclass} id="left-hero">
								<a href={void (0)} onClick={(e) => {
									this.scrollToMyRef();
									this.setState({ foodsection: "0" })
									localStorage.setItem("Foodsection", "0")
									localStorage.setItem("leftclass", "")
									localStorage.setItem("rightclass", "rightDark")
									window.location.reload()
								}}>
									<div className="left-hero-tilt left-hero-tilt-expanded">
										<div className="side-container">
											<div className="initial active">
												<span className="food-delivery-btn">
													<button>
														<span>
															<img src={require("../../static/img/white-vichle.png")} alt="" />{t("home.Food Delivery")}
														</span>
													</button>
												</span>
											</div>
										</div>
									</div>
								</a>
							</div>
							<div className={"right-hero "+ this.state.rightclass} id="right-hero" >
								<a href={void (0)} onClick={(e) => {
									this.scrollToMyRef();
									localStorage.setItem("Foodsection", "1")
									localStorage.setItem("leftclass", "leftDark")
									localStorage.setItem("rightclass", "")
									window.location.reload()
								}}>
									<div className="right-hero-tilt">
										<div className="side-container">
											<div className="initial active">
												<span className="food-delivery-right-btn">
													<button><span><img src={require("../../static/img/white-right-vichle.png")} alt="" />{t("home.Table Reservation")}</span></button>
												</span>
											</div>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</section>
				<section className="search-box aos-init aos-animate" data-aos="flip-left" id="food-delivery" ref={this.myRef}>
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="menuBar">
									{this.state.foodsection === "0" ? <span>
										<a href="/searching"><img src={require("../../static/img/filter.png")} /></a>
									</span> :
										<span>
											<a href="/tablesearching"><img src={require("../../static/img/filter.png")} /></a>
										</span>}
								</div>
								<div className="search-outer">
									<div className="delivery-location">
										<span><img src={this.state.foodsection === "0" ? require('../../static/img/delivery-bus-icon.png') : require('../../static/img/orange-right-vichle.png')} /></span>
										<span>{this.state.foodsection === "0" ? t("home.Food Delivery") : t("home.Table Reservation")}</span>
									</div>
									<div className="search-right">
										<i className="fas fa-search"></i>
										<input type="search" name="srch" placeholder={this.state.foodsection === "0" ? t("home.Search for food, dishes or restaurant") : t("tbl.Search for Table Reservation in Restaurant")}
											value={this.state.keywords} className="form-control"
											onChange={this.onChange} />
										{this.state.foodsection === "0" ?
											<span><Link to={{ pathname: "/searching", state: this.state.keywords }} className="btn">{t("home.Submit")}</Link></span> :
											<span><Link to={{ pathname: "/tablesearching", state: this.state.keywords }} className="btn">{t("home.Submit")}</Link></span>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* Categories List */}
				<Categories />
				{/* Promotion */}
				<Promotion />
				{/* Restaurants List */}
				{this.state.foodsection === "0" ? <div>
					{this.state.currencyArray.length !== 0 &&
						<RestaurantsList prerestaurantId={localStorage.getItem("restaurantId")}
							currencyArray={this.state.currencyArray} />}
				</div> :
					<div>
						{this.state.currencyArray.length !== 0 &&
							<TableRestaurantsList prerestaurantId={localStorage.getItem("restaurantId")}
								currencyArray={this.state.currencyArray} />}
					</div>}

				{/* Delivery Process */}
				{this.state.foodsection === "0" ?
					<DeliveryProcess /> :
					<TableDeliveryProcess />}

				<section className="app-download">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="mobile-img">
									<img src={this.state.foodsection === "0" ? require("../../static/img/download-left.png") : require("../../static/img/download-leftbl.png")} alt="" />
								</div>
								<div className="download-content">
									<h2 className="effect-shine">{t("login.loginf")}<span>{t("login.loginf2")}</span> </h2>
									<div className="qr-code">
										<img src={require("../../static/img/playstorehif.png")} alt="" />
										<img src={require("../../static/img/iPhoneHIF.png")} alt="" />
									</div>
									<div className="aap-store-google">
										<a href="https://play.google.com/store/apps/details?id=net.hereisfood.customer" target="_blank"><img src={require("../../static/img/google-play.png")} alt="" /></a>
										<a href="https://apps.apple.com/app/id1535470907" target="_blank"><img src={require("../../static/img/app-store.png")} alt="" /></a>
									</div>
								</div>
								<div className="food-man">
									<a href="javascript:Void(0);">
										{/*<img src={this.state.foodmanimg} alt=""
																					onMouseEnter={() => {
																						this.setState({
																							foodmanimg: require("../../static/img/food-man-eye.png")
																						})
																					}}
																					onMouseOut={() => {
																						this.setState({
																							foodmanimg: require("../../static/img/food-man.png")
																						})
																					}}
																				/>*/}
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Modal */}
				<div className={this.state.toggleAddress === false ? "modal homeAddressMOdal Customization_modal fade locationmodule " : "modal homeAddressMOdal Customization_modal fade locationmodule show modalOpenBox"} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							{this.state.addlocation && <div className="modal-header">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
									this.setState({
										toggleAddress: false,
									})
								}}><span aria-hidden="true">&times;</span>
								</button>
							</div>}
							<div className="modal-body mapOuter addressOuterMap" >
								<div className="modalBoxContent">
									<a href=""> <img src={require("../../static/img/logo.png")} alt="" /></a>
									<h3>{t("tbl.Food Delivery best restaurants near you")}</h3>
									<h3> {t("tbl.Onow")}</h3>
									<h5>{t("tbl.Specify your location")}</h5>
								</div>
								<div className="flexOuterMain">
									<PlacesAutocomplete
										value={this.state.address}
										onChange={this.handleChange}
										onSelect={this.handleSelect} >
										{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
											<div>
												<input
													{...getInputProps({
														placeholder: t('home.Search Your Places'),
														className: 'location-search-input',
													})}
												/>
												<div className="autocomplete-dropdown-container">
													{loading && <div>Loading...</div>}
													{suggestions.map(suggestion => {
														const className = suggestion.active
															? 'suggestion-item--active'
															: 'suggestion-item';
														// inline style for demonstration purpose
														const style = suggestion.active
															? { backgroundColor: '#fafafa', cursor: 'pointer' }
															: { backgroundColor: '#ffffff', cursor: 'pointer' };
														return (
															<div
																{...getSuggestionItemProps(suggestion, {
																	className,
																	style,
																})}
															>
																<span>{suggestion.description}</span>
															</div>
														);
													})}

												</div>
												<div className="centerBtn text-center">
													<button className="btn" onClick={(e) => {
														if (this.state.address === '') {
															this.setState({
																isaddress: true
															})
														} else {
															this.setState({
																toggleAddress: false
															})
															window.location.reload()
														}

													}}>{t("home.Submit")}</button>
												</div>
											</div>
										)}
									</PlacesAutocomplete>
								</div>
								{this.state.isaddress === true && <span className="text-danger">{t("home.Please enter address")}d</span>}
								<div className="modalBoxContent2"><h4>{t("home.Or install our app")}</h4><div className="aap-store-google">
									<a href="https://play.google.com/store/apps/details?id=net.hereisfood.customer" target="_blank"><img src={require("../../static/img/google-play.png")} alt="" /></a>
									<a href="https://apps.apple.com/app/id1535470907" target="_blank"><img src={require("../../static/img/app-store.png")} alt="" /></a>
								</div></div>
								{/* <iframe src={`https://www.google.com/maps/embed/v1/place?key=${apiUrl.google_Api_Key}&q=${this.state.lat},${this.state.lon}`} width="600" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe> */}
							</div>
						</div>
					</div>
				</div>
				<div className={this.state.toggleAddress === true ? "modal-backdrop fade show" : ""} onClick={(e) => {
					e.preventDefault();
					this.setState({ toggleAddress: false })
				}}></div>

				<span title={t("resd.Change Address")} className="locationMapicon" onClick={(e) => {
					this.setState({
						toggleAddress: true
					})
				}}><i className="fas fa-map-marker-alt"></i></span>
			</div >
		);
	}
}



export default withTranslation(Home);
