import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import './App.css';
import './static/css/bootstrap.min.css';
import './static/css/style.css';
import './static/css/media.css';
import './static/fonts/fontawesome-5/css/all.css';
import { DefaultLayout } from './containers';
import More from './views/Pages/More/More';
import UserChangePassword from './views/Pages/Forgot/ForgotChangePassword';
//import ReactGA from 'react-ga';
//import GTagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import createHistory from 'history/createBrowserHistory';

//ReactGA.initialize([{trackingId:'G-6HSETJTMGE'},{trackingId:'AW-449064384'}],{debug:true});
//GTagManager.initialize({gtmId:'G-6HSETJTMGE'},{gtmId:'AW-449064384'})
ReactPixel.init('3727661790658519');
const history=createHistory();
history.listen((loc,act)=>{
	//ReactGA.pageview(loc.pathname+loc.search);
	ReactPixel.pageView();
});

class App extends Component {
  render() {
	return (
		<BrowserRouter>
			<Switch>
				{/* <Route path="/more/:link" name="More" component={More} /> */}
				<Route exact path="/user-account" name="Password Reset Page" component={UserChangePassword} />
				<Route path="/" name="Default" component={DefaultLayout} />
			</Switch>
		</BrowserRouter>
	);
  }
}

export default App;
