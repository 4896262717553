import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import apiUrl from '../../../constants/apiPath';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../Common/TextValidator';
import Helper from '../../../constants/helper';
import Swal from 'sweetalert2';
import Loader from '../../Common/Loader';
import queryString from 'query-string';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang';
import tr from '../../MultLanguage/tr.json';
import en from '../../MultLanguage/en.json';
import de from '../../MultLanguage/de.json';
import es from '../../MultLanguage/es.json';
import fr from '../../MultLanguage/fr.json';
import lt from '../../MultLanguage/lt.json';
import ru from '../../MultLanguage/ru.json';

// Do this two lines only when setting up the application
let language = localStorage.getItem('Language') || 'en'
switch (localStorage.getItem('Language') || 'en') {
	case "tr":
		setTranslations({ tr })
		setDefaultLanguage(language.toString())
		break;
	case "fr":
		setTranslations({ fr })
		setDefaultLanguage(language.toString())
		break;
	case "es":
		setTranslations({ es })
		setDefaultLanguage(language.toString())
		break;
	case "ru":
		setTranslations({ ru })
		setDefaultLanguage(language.toString())
		break;
	case "lt":
		setTranslations({ lt })
		setDefaultLanguage(language.toString())
		break;
	case "de":
		setTranslations({ de })
		setDefaultLanguage(language.toString())
		break;
	default:
		setTranslations({ en })
		setDefaultLanguage('en')

}

const Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000
});

class ForgotChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			password: "", confirm_password: "", viewpass: false, viewcpass: false,
			token: queryString.parse(this.props.location.search).token,
			language: localStorage.getItem('Language') || 'en',
		}

	}

	componentDidMount() {	   
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			if (value !== this.state.password) {
				return false;
			}
			return true;
		});

	}


	handleSubmit = async () => {
		this.setState({
			visible: true
		})
		let postJson = { token: this.state.token, language: this.state.language, password: this.state.password };
		let path = apiUrl.forgot_ChangePassword;
		const fr = await Helper.post(postJson, path);
		const res = await fr.response.json();
		if (fr.status === 200) {
			if (res.success) {
				this.setState({
					visible: false
				})
				Toast.fire({
					type: "success",
					title: res.msg,
				});
			} else {
				this.setState({
					visible: false
				})
				Toast.fire({
					type: "error",
					title: res.msg,
				});
			}
		} else {
			Toast.fire({
				type: "error",
				title: res.error,
			});

		}
	}



	render() {
		const { t } = this.props
		return (
			<div className="app flex-row align-items-center login-body">
				<Container>
					<Loader visible={this.state.visible} />
					<Row className="justify-content-center">
						<Col md="8">
							<CardGroup>
								<Card className="p-4">
									<CardBody>
										<ValidatorForm onSubmit={this.handleSubmit}
											id="changepass" >
											<h1>{t("profile.Change Password")}</h1>
											<p className="text-muted">{t("resd.Enter your new password")} </p>
											<InputGroup className="mb-3">
												<div class="form-group input-outer">
													<TextValidator type={this.state.viewpass === false ? "Password" : "text"} maxLength="25" class="form-control" placeholder={t("change.new")}
														value={this.state.password}
														onChange={(e) => {
															this.setState({ password: e.target.value })
														}}
														validators={['required', 'matchRegexp:^(?=.*[a-zA-Z]).{6,25}$']}
														errorMessages={[t('login.Please enter password'), t('register.errormsg')]} />
													<span onClick={(e) => this.setState({ viewpass: !this.state.viewpass })}>{this.state.viewpass === false ? <i class="fas fa-eye-slash"></i> : <i class="fas fa-eye"></i>}</span>
												</div>
												<div class="form-group input-outer">
													<TextValidator type={this.state.viewcpass === false ? "Password" : "text"} maxLength="25" class="form-control" placeholder={t("register.Confirm password")}
														value={this.state.confirm_password}
														onChange={(e) => {
															this.setState({ confirm_password: e.target.value })
														}}
														validators={['required', 'isPasswordMatch']}
														errorMessages={[t('register.Please enter confirm password'), t('register.Passwords do not match')]} />
													<span onClick={(e) => this.setState({ viewcpass: !this.state.viewcpass })}>{this.state.viewcpass === false ? <i class="fas fa-eye-slash"></i> : <i class="fas fa-eye"></i>}</span>
												</div>
											</InputGroup>
											<Row>
												<Col xs="4">
													<Button color="primary" type="submit" className="px-4">{t("home.Submit")}</Button>
												</Col>
												<Col xs="8" className="backLogin text-right">
													<a href="/login" color="link" className="px-0">{t("resd.Back to Login")}</a>
												</Col>
											</Row>
										</ValidatorForm>
									</CardBody>
								</Card>
								<Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
									<CardBody className="text-center">
										<div>
											<h2>Here is Food</h2>
											{/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
													 labore et dolore magna aliqua.</p> */}
										</div>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default withTranslation(ForgotChangePassword);
